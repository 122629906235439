.docs{
    display: flex;
    position: relative;
}
.docCon{
    margin-top: 6rem;
    position: relative;
}
.docsNav{
    padding-inline: 2rem;
    position: fixed;
    width: inherit;
    margin: auto;
    /* background-color: rgb(244, 242, 242); */
    background-color: white;
    border-bottom: 1px solid rgb(244, 242, 242);
    z-index: 40;
    top: 0;
    left: 0;
    right: 0;
}
.docs nav {
    width: 20%;
    border-right: 2px solid rgb(244, 242, 242);
    padding-right: 2rem;
    height: 100vh; /* Fix nav height to the viewport */
    padding-top: 2rem;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden; /* Disable overflow for the nav itself */
    overflow-y: scroll;
}
.docs .subHeader{
    margin: 0;
    font-size: 16px;
    font-family: Mont m;
    margin-bottom: 1rem;
}
.docs .subnav{
    margin-left: -2rem;
}
.docs nav ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
     /* max-height: calc(100vh - 4rem); */
    padding-right: 1rem; /* Optional: Add padding for a cleaner scroll */
}
.docs nav div{
    padding-block: 5rem;
    padding-bottom: 10rem;
}
.docs nav a{
    text-decoration: none;
    color: inherit;
    font-size: 18px;
    font-family: Mont l;
    font-weight: 500;
}
.docs nav .active{
    font-size: 18px;
    font-weight: 900;
}
.docs section{
    padding: 2rem;
    padding-top: 3rem;
    position: absolute;
    left: 22%;
}
.intro{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.intro h1{
    font-family: Mont;
    margin: 0;
    font-size: 26px;
}
.intro h3{
    font-family: Mont m;
    font-size: 27px;
}
.intro article{
    /* border: 1px solid; */
    margin-left: 1rem;
    font-family: Mont l;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
}
.intro article strong{
    font-family: Mont m;
}
.intro article img{
    width: 80%;
}
.intro article ol{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.intro article a{
    color: blue;
}

@media (max-width: 900px) {
    .docs nav{
        /* display: none; */
        transform: translateX(-100%);
        width: 50%;
        z-index: 45;
        background-color: white;
        padding: 0;
        transition: .3s ease-in-out;
    }
    .docs .openmenu{
        transform: translateX(0%);
    }
    .docs nav div{
        padding-block: 0rem;
        margin-left: -1rem;
    }
    .docs nav #menu{
        width: 20px;
    }
    .docsNav{
        padding-inline: 1rem;
        width: inherit;
        margin: initial;
    }
    .docs nav a{
        font-size: 14px;
    }
    .docs .subHeader{
        font-size: 14px;
    }
    .docs section{
        width: inherit;
        left: 0;
        padding: 1rem;
        top: 0;
    }
    .intro h1{
        font-size: 22px;
    }
    .intro h3{
        font-size: 20px;
    }
    .intro article{
        font-size: 14px;
    }
}