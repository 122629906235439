.nav nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Walkway;
    font-size: 22px;
}
.nav nav img{
    width: 200px;
    height: 100px;
    object-fit: contain;
}
.nav ul{
    list-style: none;
    display: flex;
    gap: 1.5rem;
    align-items: center;
}
.nav button{
    padding: 10px 20px;
    background-color: #0481AE;
    background: linear-gradient(180deg, #0481AE, #04283A, #0481AE);
    border: none;
    border-radius: 15px;
    color: white;
    font-family: Walkway;
    font-size: 18px;
}
.nav a{
    text-decoration: none;
    color: inherit;
}
.Hero{
    margin-inline: -3rem;
}
.Hero section{
    display: flex;
    align-items: center;
    padding-inline: 3rem;
    margin-inline: -3rem;
    background-color: #f5f7fa;
    gap: 5%;
}
.Hero section article{
    width: 50%;
    display: flex;
    flex-direction: column;
        gap: 1rem;
        margin-left: 3rem;
}
.Hero section article h1{
    font-family: Walkway;
    font-size: 75px;
    margin: 0;
    letter-spacing: 10px;
    background: linear-gradient(180deg, #0481AE, #04283A, #0481AE);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.Hero section article h1 span{
    color: #0481AE;
}
.Hero section article p{
    font-size: 20px;
    font-weight: 500;
}
.Hero section article button{
    width: fit-content;
    padding: 12px 24px;
    background-color: #0481AE;
    background: linear-gradient(180deg, #0481AE, #04283A, #0481AE);
    border: none;
    border-radius: 15px;
    color: white;
    font-family: Walkway;
    font-size: 20px;
    letter-spacing: 3px;
}
.Hero section main{
    width: 45%;
}
.Hero section main img{
    width: 100%;
}
.features section{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}
.features section h1{
    font-family: Walkway;
    font-size: 50px;
    letter-spacing: 4px;
}
.features section article ul{
    list-style: none;
    display: flex;
    align-items: center;
    margin-left: -1rem;
    margin-bottom: 2rem;
    gap: 3%;
}
.features section article ul li{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 30%;
}
.features section article ul li p{
    width: 100%;
}
.comm section{
    display: flex;
    flex-direction: column;
    background-color: #f5f7fa;
    padding-inline: 3rem;
    margin-inline: -3rem;
    padding-bottom: 4rem;
    margin-bottom: 3rem;
}
.comm section article{
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    /* width: 50%; */
    align-items: center;
}
.comm section article button{
    width: fit-content;
    padding: 12px 24px;
    background-color: #0481AE;
    border: none;
    border-radius: 5px;
    color: white;
    font-family: Walkway;
    font-size: 20px;
    letter-spacing: 2px;
    margin-left: auto;
    margin-right: 2rem;
}
.comm section article main{
    /* width: 50%; */
    display: flex;
    flex-direction: column;
    align-items: center;
}
.comm section article main h1{
    font-family: Walkway;
    font-size: 40px;
    letter-spacing: 3px;
}
.comm section article main p{
    width: 90%;
    /* font-family: Walkway; */
    line-height: 30px;
    font-size: 20px;
}
.comm section article img{
    width: 300px;
    height: 300px;
    border-radius: 10px;
    /* border-radius: 50%; */
}
.use section{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
.use h1{
    font-size: 40px;
}
.use main{
    display: flex;
    gap: 2rem;
}
.use button{
    width: fit-content;
    padding: 12px 24px;
    background-color: #0481AE;
    background: linear-gradient(180deg, #0481AE, #04283A, #0481AE);
    border: none;
    border-radius: 5px;
    color: white;
    font-family: Walkway;
    font-size: 20px;
    letter-spacing: 2px;
    margin-left: auto;
    margin-right: 2rem;
}
.use button a{
    text-decoration: none;
    color: inherit;
}
.pricing{
    margin-top: 4rem;
    border-top: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
    padding-block: 4rem;
}
.pricing section header, .faq section header{
    display: flex;
    flex-direction: column;
    text-align: center;
}
.pricing section header h1,.faq section header h1{
    font-family: Walkway;
    font-size: 40px;
    letter-spacing: 2px;
    margin: 0;
}
.pricing .eachpriceCon{
    list-style: none;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.pricing .eachpriceCon .eachprice{
    border: 1px solid #0481AE;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-block: 2rem;
    border-radius: 5px;
}
.pricing .eachpriceCon .eachprice h3{
    margin: 0;
    font-family: Walkway;
    font-size: 25px;
    letter-spacing: 2px;
    margin-bottom: 5px;
    display: flex;
    letter-spacing: 2px;
    align-items: center;
    gap: 1rem;
}
.pricing .eachpriceCon .eachprice p span{
    color: #0481AE;
    font-weight: 700;
}
.pricing .eachpriceCon .eachprice p{
    margin: 0;
}
.pricing .eachpriceCon .eachprice img{
    width: 30px;
}
.pricing .eachpriceCon .eachprice ul{
    list-style: none;
    margin-left: -2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}
.pricing .eachpriceCon .eachprice ul button{
    width: fit-content;
    padding: 12px 24px;
    background-color: #0481AE;
    border: none;
    border-radius: 5px;
    color: white;
    font-family: Walkway;
    font-size: 14px;
    letter-spacing: 2px;
    background: linear-gradient(180deg, #0481AE, #04283A, #0481AE);
}
.lounge section{
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
}
.lounge button{
    width: fit-content;
    padding: 12px 24px;
    background-color: #0481AE;
    background: linear-gradient(180deg, #0481AE, #04283A, #0481AE);
    border: none;
    border-radius: 5px;
    color: white;
    font-family: Walkway;
    font-size: 22px;
    letter-spacing: 2px;
    margin-left: auto;
    margin-right: 2rem;
} 
.faq{
    margin-top: 4rem;
}
.accordion{
    width: 70%;
    margin: auto;
    margin-top: 2rem;
    font-family: Walkway;
}
.accordion p{
    font-family: 'Times New Roman', Times, serif;
    font-size: 16px;
    line-height: 22px;
}
.foot{
    background-color: #04283A;
    margin-inline: -3rem;
    padding-inline: 3rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    margin-top: 4rem;
    color: white;
    font-family: Walkway;
}
.foot footer .logo2{
    width: 200px;
}
.foot footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.foot .socials{
    display: flex;
    gap: 2rem;
    margin-block: 1rem;
    margin-bottom: 3rem;
}
.foot a{
    text-decoration: none;
    color: inherit;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
}
.prevNext{
    display: flex;
    justify-content: space-between;
    padding-inline: 1rem;
    font-family: Mont m;
}
.prevNext a{
    display: flex;
    gap: 5px;
    border: 1px solid gainsboro;
    padding: 10px 20px;
    align-items: center;
    text-decoration: none;
    color: inherit;
} 

@media (min-width:751px) and (max-width:1200px){
    .nav li{
        font-size: 14px;
    }
    .nav nav img{
        width: 200px;
        height: 100px;
        object-fit: contain;
    }
    .Hero section main img{
        width: 400px;
    }
    .Hero section article h1{
        font-size: 40px;
    }
    .Hero section article p{
        font-size: 16px;
        font-weight: 500;
    }
    .Hero section article button{
        font-size: 18px;
    }
    .Hero section article{
        width: 50%;
        margin-left: 1rem;
    }
    .Hero section main{
        width: 50%;
        display: flex;
        justify-content: center;
    }
    .features section h1{
        font-size: 30px;
    }
    .features section article ul li p{
       font-size: 12px;
       width: 90%;
    }
    .use h1{
        font-size: 20px;
    }
    .use main{
        gap: 1rem;
    }
    .use button{
        font-size: 14px;
    }
    .pricing .eachpriceCon{
        /* flex-direction: column; */
        margin-left: -2rem;
        flex-wrap: wrap;
    }
    .pricing section header h1, .faq section header h1{
        font-size: 30px;
    }
    .pricing .eachpriceCon .eachprice{
        width: 40%;
    }
    .lounge button{
        font-size: 16px;
    }
    .foot footer .logo2{
        width: 200px;
    }
    .foot a img{
        width: 20px;
    }
    .foot a{
        text-decoration: none;
        color: inherit;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
    }
}

@media (max-width:750px) {
    .nav{
        width: 100%;
        margin: 0;
        margin-inline: -1rem;
        padding-inline: 1rem;
    }
    .nav nav{
        width: 100%;
        justify-content: flex-start;
        justify-content: space-between;
    }
    .nav nav img{
        width: 150px;
        height: 75px;
        object-fit: contain;
    }
    .nav ul .navLink{
        display: none;
    }
    .nav ul li button{
        display: block;
    }
    .Hero{
    padding-inline: 1rem;
    margin-inline: -1rem;
    justify-content: center;

        width: 100%;
        align-items: center;
        overflow-x: hidden;
    }
    .Hero section{
        flex-direction: column;
    padding-block: 3rem;
    align-items: center;
    justify-content: center;
    width: fit-content;
    }
    .Hero section main img{
        width: 100%;
    }
    .Hero section article{
        align-items: center;
        margin-left: 0;
    }
    .Hero section article h1{
        font-size: 35px;
        text-align: center;
        line-height: 40px;
        letter-spacing: 4px;
    }
    .Hero section article p{
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    }
    .Hero section article button{
        font-size: 16px;
        margin: auto;
    }
    .Hero section article{
        width: 100%;
    }
    .Hero section main{
        width: 109%;
        display: flex;
        /* border: 1px solid; */
        justify-content: center;
    }
    .Hero section main img{
        width: 100%;
    }
    .features section h1{
        font-size: 20px;
        text-align: center;
        margin-left: 0rem;
    }
    .features section article ul{
        flex-direction: column;
        gap: 1rem;
        margin-left: -2rem;
    }
    .features section article ul li{
        width: 90%;
        border: 1px solid gainsboro;
        border: none;
        border-radius: 6px;
        padding: 10px;
        background-color: #fafafa;

    }
    .features section article ul li img{
        width: 30px;
    }
    .features section article ul li h3{
        font-size: 14px;
        margin: 0;
    }
    .features section article ul li p{
        width: 90%;
        font-size: 12px;
        line-height: 20px;
    }
    .comm{
        display: none;
    }
    .use section{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
    .use h1{
        font-size: 20px;
    }
    .use main{
        align-items: center;
        justify-content: center;
    }
    .use button{
        font-size: 14px;
        margin-right: 0;
    }
    .pricing .eachpriceCon{
        flex-direction: column;
        margin-left: -2rem;
    }
    .pricing section header h1, .faq section header h1{
        font-size: 20px;
    }
    .pricing .eachpriceCon .eachprice{
        width: 80%;
    }
    .lounge section{
        gap: 0rem;
        margin-left: 1rem;
    }
    .lounge button{
        font-size: 14px;
    }
    .accordion{
        width: 80%;
        margin-left: 2rem;
    }
    .accordion .accheader{
        font-size: 16px;
    }
    .accordion p{
        font-family: 'Times New Roman', Times, serif;
        font-size: 12px;
        line-height: 18px;
    }
    .foot footer .logo2{
        width: 100px;
    }
    .foot{
        background-color: #04283A;
        margin-inline: -1rem;
        padding-inline: 1rem;
        padding-top: 2rem;
        padding-bottom: 1rem;
        margin-top: 4rem;
        color: white;
        font-family: Walkway;
        overflow-x: hidden;
    }
    .foot a img{
        width: 20px;
    }
    .foot a{
        text-decoration: none;
        color: inherit;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
    }
}